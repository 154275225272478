.wd-modules,
.wd-modules * {
    border-radius: 0%;
    border: none;
    margin: 0px;
    padding: 0px;
}

.wd-modules {
    margin-top: 5px;
}

.wd-modules>li {
    background-color: lightgray;
    border: 1px solid gray !important;
    border-left: none !important;
}

.wd-modules>li>div {
    padding: 6px;
    border-left: 1px solid gray !important;
}

.wd-modules>li>ul>li {
    padding: 5px;
    border-top: 1px solid gray !important;
    border-left: 2px solid green;
}

.wd-modules>li {
    margin-bottom: 20px;
}

.control-buttons button {
    border: none;
    border-radius: 4px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-gray,
.btn-gray:hover,
.btn-gray:active,
.btn-gray:focus {
    background-color: lightgray;
    color: black;
}