.wd-kanbas-navigation {
    background-color: black;
    list-style: none;
    padding: 0px;
    margin: 0px;
    width: 80px;
    min-height: 100vh;
    height: 100%;
}

.wd-kanbas-navigation li {
    padding: 5px;
    margin-bottom: 10px;
    font-size: 0.85em;
    text-align: center;
    color: red;
}

.wd-kanbas-navigation li .label {
    color: white;
}

.wd-kanbas-navigation li.wd-active .label {
    color: red;
}

.wd-kanbas-navigation li.wd-active {
    background-color: white;
}

/* .wd-kanbas-navigation li.wd-active a {
    color: red;
} */

.wd-kanbas-navigation a {
    text-decoration: none;
}

/* .wd-kanbas-navigation a i {
    color: red;
    font-size: 2em;
    margin-bottom: 5px;
} */